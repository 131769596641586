import {React, useEffect, useState} from 'react';
import { Col, Row, Container, Image, Modal, Button, Carousel} from 'react-bootstrap';
import '../css/ourwork.css';
import { propTypes } from 'react-bootstrap/esm/Image';

function OurWork(){

  const [images, setImages] = useState([]);
  const [imagesFiltered, setImagesFiltered] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);

  useEffect(() => {
    fetch("/api/RetrieveBlobImages", {
      method: "GET",
      headers: {
          "Content-type": "application/json; charset=UTF-8"
      }
    })
    .then(response => response.json())
    .then((responseData => 
    {
      setImages(responseData.Images);
      setImagesFiltered(responseData.Images);
    }));
  }, []);

  const handleSelectedValue = (event) => {
    setImagesFiltered(event.target.value === 'all'
    ? images
    : images.filter(image => image.Directory === event.target.value));
  };

  const handleImageClick = (index) => {
    setSelectedImageIndex(index);
    setShowModal(true);
  };

  const handleSelect = (selectedIndex) => {
    setSelectedImageIndex(selectedIndex);
  };

  return (
    <Container id='container'>
      <Row>
        <Col>
          <h1 id='galleryTitle'>Explore photos of the services we provide</h1>
          <h4 id='instructions'>
            1. Choose a service from the dropdown menu below.<br/>
            2. Click on any picture to view a larger slideshow.<br/>
            3. Contact us for any questions or to request a quote.
          </h4>
        </Col>
      </Row>
      <Row id='selectRow'>
        <Col xs={12} sm={12} md={5} lg={5} id='backgroundForSelect'>
          <select name="options" id='dropdownToggle' onChange={handleSelectedValue}>
            <option value="all">All</option>
            <option value="concrete">Concrete</option>
            <option value="drainage">Drainage</option>
            <option value="gravel">Gravel</option>
            <option value="lawninstallation">Lawn Installation</option>
            <option value="pavers">Pavers</option>
            <option value="retainingwalls">Retaining Walls</option>
            <option value="softscape">Softscapes</option>
            <option value="treework">Tree Work</option>
          </select>
        </Col>
      </Row>
      <Row id='imagesRow'>
        <Col className="gallery" xs={12} sm={12} md={6} lg={8}>
            {imagesFiltered.map((image, index) => (
                <Image key={index} src={image.LinkToPicture} onClick={() => handleImageClick(index)} alt={`Image ${index + 1}`} />
            ))}
        </Col>
      </Row>
      <GalleryModal
        show={showModal}
        onHide={() => setShowModal(false)}
        imagesFiltered={imagesFiltered}
        selectedImageIndex={selectedImageIndex}
        handleSelect={handleSelect}
      />
    </Container>
  );
}

function GalleryModal(props){
  return (
    <Modal {...props} size="xl" id='customizeModal' centered>
      <Modal.Body>
        <Carousel interval={10000} activeIndex={props.selectedImageIndex} onSelect={props.handleSelect}>
        {props.imagesFiltered.map((image, index) => (
          <Carousel.Item key={index}>
            <Image key={index} src={image.LinkToPicture} alt={`Image ${index + 1}`} className='carousel-image'/>
          </Carousel.Item>
        ))}
        </Carousel>
      </Modal.Body>
      <Modal.Footer>
      <Button onClick={props.onHide} id='closeGalleryButton'>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

GalleryModal.propTypes = {
  onHide: propTypes.bool,
  handleSelect: propTypes.bool,
  imagesFiltered: propTypes.array,
  selectedImageIndex: propTypes.number
};
  
export default OurWork;